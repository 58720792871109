import React from 'react';
import ContantContainerMain from '../../total/ContantContainerMain';
import s from './HumanResourcesManagement.module.css';

import { NavLink } from "react-router-dom";
import { ROUTER } from '../../config';
import CardHuman from '../../Components/CardHuman';
import HeadDepartment from '../../assets/img/Persons/poznyakova.png';

import LinkContainer from '../../Components/LinkContainer';

const HumanResourcesManagement = (props) => {
   return (
      <div>
         <ContantContainerMain>
            <div className={`mt80 breadcrumbs`}>
               <NavLink to={ROUTER.activity.main} className='breadcrumbsFrom'>Деятельность</NavLink>
               <span className='breadcrumbsTo'> / Кадровое управление</span>
            </div>
            <div className={`mt24 pageTitle`}>Кадровое управление</div>

            <div className={`mt80 borderMain ${s.linkContainer}`}>
               <LinkContainer link='http://old.admhimki.ru/okrug/deyatelnost/kadrovoe-obespechenie/poryadok-postupleniya-grazhdan-na-gosudarstvennuyu-i-municipalnuyu-sluzhbuu/' text={'Порядок поступления граждан на государственную и муниципальную службу'} />
               <div className="mt24"><LinkContainer link='http://old.admhimki.ru/okrug/deyatelnost/kadrovoe-obespechenie/kontakty-upravleniya-municipalnoj-sluzhby-i-kadrov/' text={'Контакты управления муниципальной службы и кадров'} /></div>
               <div className="mt24"><LinkContainer link='http://old.admhimki.ru/okrug/deyatelnost/kadrovoe-obespechenie/pravovoe-obespechenie/' text={'Правовое регулирование'} /></div>
               <div className="mt24"><LinkContainer link='http://old.admhimki.ru/okrug/deyatelnost/kadrovoe-obespechenie/vedomstvennyj-kontrol-za-soblyudeniem-trudovogo-zakonodatelstva/' text={'Ведомственный контроль за соблюдением трудового законодательства'} /></div>
               <div className="mt24"><LinkContainer link='https://old.admhimki.ru/okrug/deyatelnost/kadrovoe-obespechenie/svedeniya-o-vakantnyh-dolzhnostyah/' text={'Сведения о вакантных должностях'} /></div>
            </div>

            <div className="mt80 pageSubtitle">Начальник управления</div>
            <CardHuman
               jobTitle={'Управление «Аппарат Администрации городского округа и кадровой политики»'}
               name={'Познякова'}
               surname={'Ирина Александровна '}
               info={'Контакты для обращения граждан:'}
               contactsArr={['+7 (495) 572-65-27', 'hr_manager@admhimki.ru']}
               img={HeadDepartment}
            />
            <div className={`mt80 text`}>
               <div className="pageSubtitle">Порядок поступления граждан на государственную службу, муниципальную службу</div>
               <ol className='mt38'>
                  <li>На муниципальную службу вправе поступать граждане, достигшие возраста 18 лет, владеющие государственным языком Российской Федерации и соответствующие квалификационным требованиям, установленным в соответствии с настоящим Федеральным законом для замещения должностей муниципальной службы, при отсутствии обстоятельств, указанных в статье 13 настоящего Федерального закона в качестве ограничений, связанных с муниципальной службой.</li>
                  <li>При поступлении на муниципальную службу, а также при ее прохождении не допускается установление каких бы то ни было прямых или косвенных ограничений или преимуществ в зависимости от пола, расы, национальности, происхождения, имущественного и должностного положения, места жительства, отношения к религии, убеждений, принадлежности к общественным объединениям, а также от других обстоятельств, не связанных с профессиональными и деловыми качествами муниципального служащего.</li>
                  <li><div className="fw600">При поступлении на муниципальную службу гражданин представляет:</div></li>
               </ol>
               <ul className="mt38">
                  <li>заявление с просьбой о поступлении на муниципальную службу и замещении должности муниципальной службы;</li>
                  <li>собственноручно заполненную и подписанную анкету по форме, установленной уполномоченным Правительством Российской Федерации федеральным органом исполнительной власти;</li>
                  <li>паспорт;</li>
                  <li>трудовую книжку, за исключением случаев, когда трудовой договор (контракт) заключается впервые;</li>
                  <li>документ об образовании;</li>
                  <li>страховое свидетельство обязательного пенсионного страхования, за исключением случаев, когда трудовой договор (контракт) заключается впервые;</li>
                  <li>свидетельство о постановке физического лица на учет в налоговом органе по месту жительства на территории Российской Федерации;</li>
                  <li>документы воинского учета — для граждан, пребывающих в запасе, и лиц, подлежащих призыву на военную службу;</li>
                  <li>заключение медицинской организации об отсутствии заболевания, препятствующего поступлению на муниципальную службу;</li>
                  <li>сведения о доходах за год, предшествующий году поступления на муниципальную службу, об имуществе и обязательствах имущественного характера;</li>
                  <li>иные документы, предусмотренные федеральными законами, указами Президента Российской Федерации и постановлениями Правительства Российской Федерации.</li>
               </ul>
               <ol start='4' className='mt38'>
                  <li>Сведения, представленные в соответствии с настоящим Федеральным законом гражданином при поступлении на муниципальную службу, могут подвергаться проверке в установленном федеральными законами порядке. В отдельных муниципальных образованиях федеральными законами могут устанавливаться дополнительные требования к проверке сведений, представляемых гражданином при поступлении на муниципальную службу.</li>
                  <li>В случае установления в процессе проверки, предусмотренной частью 4 настоящей статьи, обстоятельств, препятствующих поступлению гражданина на муниципальную службу, указанный гражданин информируется в письменной форме о причинах отказа в поступлении на муниципальную службу.</li>
                  <li>Поступление гражданина на муниципальную службу осуществляется в результате назначения на должность муниципальной службы на условиях трудового договора в соответствии с трудовым законодательством с учетом особенностей, предусмотренных настоящим Федеральным законом.</li>
                  <li>Гражданин, поступающий на должность главы местной администрации по результатам конкурса на замещение указанной должности, заключает контракт. Порядок замещения должности главы местной администрации по контракту и порядок заключения и расторжения контракта с лицом, назначаемым на указанную должность по контракту, определяются Федеральным законом от 6 октября 2003 года N 131-ФЗ «Об общих принципах организации местного самоуправления в Российской Федерации». Типовая форма контракта с лицом, назначаемым на должность главы местной администрации по контракту, утверждается законом субъекта Российской Федерации.</li>
                  <li>Поступление гражданина на муниципальную службу оформляется актом представителя нанимателя (работодателя) о назначении на должность муниципальной службы.</li>
                  <li>Сторонами трудового договора при поступлении на муниципальную службу являются представитель нанимателя (работодатель) и муниципальный служащий.</li>
               </ol>
               <div className="mt38 pageSubtitle">Усовия конкурса на замещение должности муниципальной службы</div>
               <ol className='mt38'>
                  <li>При замещении должности муниципальной службы в муниципальном образовании заключению трудового договора может предшествовать конкурс, в ходе которого осуществляется оценка профессионального уровня претендентов на замещение должности муниципальной службы, их соответствия установленным квалификационным требованиям к должности муниципальной службы.</li>
                  <li>Порядок проведения конкурса на замещение должности муниципальной службы устанавливается муниципальным правовым актом, принимаемым представительным органом муниципального образования. Порядок проведения конкурса должен предусматривать опубликование его условий, сведений о дате, времени и месте его проведения, а также проекта трудового договора не позднее чем за 20 дней до дня проведения конкурса. Общее число членов конкурсной комиссии в муниципальном образовании и порядок ее формирования устанавливаются представительным органом муниципального образования.</li>
                  <li>
                     Представитель нанимателя (работодатель) заключает трудовой договор и назначает на должность муниципальной службы одного из кандидатов, отобранных конкурсной комиссией по результатам конкурса на замещение должности муниципальной службы.
                  </li>
               </ol>
               <div className={`mt38 ${s.description}`}>В соответствии со ст. 17 Федерального закона от 2 марта 2007 года N 25-ФЗ «О муниципальной службе в Российской Федерации» и решением Совета депутатов муниципального образования городского округа Химки Московской области от 26.12.2019 № 31/11 «Об утверждении Положения о порядке проведения конкурса на замещение вакантной должности муниципальной службы в органе местного самоуправления городского округа Химки Московской области»</div>

            </div>


         </ContantContainerMain>
      </div>
   )
}
export default HumanResourcesManagement;
import React from "react";
import ContantContainerMain from "../../total/ContantContainerMain";
import s from "./ContestsFinancialSupport.module.css";
import { NavLink } from "react-router-dom";
import { ROUTER } from "../../config";
import DocumentContainerDownload from "../../Components/DocumentContainerDownload";

const ContestsFinancialSupport = (props) => {
  return (
    <div>
      <ContantContainerMain>
        <div className={`mt80 breadcrumbs`}>
          <NavLink to={ROUTER.activity.main} className="breadcrumbsFrom">
            Деятельность
          </NavLink>
          <NavLink
            to={ROUTER.activity.smallMediumBusinesses.main}
            className="breadcrumbsFrom"
          >
            {" "}
            / Малое и среднее предпринимательство
          </NavLink>
          <span className="breadcrumbsTo">
            {" "}
            / Конкурсы на предоставления финансовой поддержки
          </span>
        </div>
        <div className={`mt24 pageTitle`}>
          <p>Конкурсы на предоставления</p>
          финансовой поддержки
        </div>

        <div className="text">
          <div className="mt80 pageSubtitle">
            Конкурсы на предоставления финансовой поддержки субъектам малого и
            среднего предпринимательства на право получения субсидии
          </div>
          <div className="mt38 fw600">
            Конкурс по отбору лиц для предоставления финансовой поддержки
            субъектам малого и среднего предпринимательства на право получения
            субсидии за счет средств бюджета городского округа Химки Московской
            области на реализацию мероприятий подпрограммы «Развитие малого и
            среднего предпринимательства» муниципальной программы городского
            округа Химки Московской области «Предпринимательство» в соответствии
            с постановлением Администрации от 19.09.2023 № 1508 «Об утверждении
            Порядка предоставления финансовой поддержки (субсидий) субъектам
            малого и среднего предпринимательства в рамках подпрограммы
            «Развитие малого и среднего предпринимательства» муниципальной
            программы городского округа Химки «Предпринимательство» (далее -
            Порядок) Администрация городского округа Химки Московской области
            (далее - Администрация).Организатор конкурса: Администрация.
          </div>
          <p>
            Адрес: 141400, Московская область, городской округ Химки, ул.
            Московская, д.15, ул. Калинина, д. 4.
          </p>
          <p>
            <span className="fw600">Прием Заявлений </span>
            на предоставление финансовой поддержки и выдача результатов их
            рассмотрения осуществляется в электронной форме посредством РПГУ
            (государственная информационная система Московской области «Портал
            государственных и муниципальных услуг (функций) Московской
            области»).
          </p>
          <p>Контактная информация:</p>
          <div className={s.contactsContainer}>
            <div className="mt38 oval">+7 (495) 573-31-62</div>
            <div className="mt38 oval">info.mbh@admhimki.ru</div>
          </div>
          <div className={`mt38 juridicalInfo`}>
            Приёмная МКУ «Малый бизнес Химки».
          </div>
          <div className="mt38 fw600">
            Дата начала подачи (приема) заявок участников Конкурса 00:00 часов
            01.10.2024, дата окончания 23:59 часов 30.10.2024 (включительно) по
            московскому времени;
          </div>
          <p>
            В рамках отбора распределяются бюджетные ассигнования в сумме 6 000
            000 (шести миллионов) рублей;
          </p>
          <p>Период возмещения затрат с 01.01.2024 по день подачи заявки.</p>
          <div className="mt38 fw600">Предмет Конкурса.</div>
          <p>
            Предметом Конкурса является определение субъектов малого и среднего
            предпринимательства, имеющих право на заключение договора c
            Администрацией о предоставлении субсидии на реализацию мероприятий
            подпрограммы «Развитие малого и среднего предпринимательства»
            муниципальной программы городского округа Химки Московской области
            «Предпринимательство»:
          </p>
          <ol>
            <li>
              «Частичная компенсация субъектам малого и среднего
              предпринимательства затрат, связанных с приобретением
              оборудования»;
            </li>
            <li>
              «Частичная компенсация затрат субъектам малого и среднего
              предпринимательства, осуществляющим деятельность в сфере
              социального предпринимательства».
            </li>
          </ol>
          <p>
            <span className="fw600">Конкурсная комиссия </span>- комиссия по
            отбору заявительных документов субъектов МСП на право заключения
            договора о предоставлении субсидии за счет средств бюджета
            городского округа Химки Московской области.
          </p>
          <p>
            <span className="fw600">Участник Конкурса </span>- субъект малого
            или среднего предпринимательства, подавший заявление на
            предоставление финансовой поддержки (субсидии).
          </p>
          <div className="mt38 fw600">
            Условия и порядок проведения конкурса
          </div>
          <p>
            Условия и порядок проведения Конкурса определены Порядком
            предоставления за счет средств бюджета городского округа Химки
            Московской области субсидий субъектам малого и среднего
            предпринимательства на проведение мероприятий подпрограммы «Развитие
            малого и среднего предпринимательства» муниципальной программы
            городского округа Химки Московской области «Предпринимательство»,
            утвержденного
            <a
              href="https://old.admhimki.ru/media/eds/elements/7b16364b-b7b9-43a9-bc8d-42fd7876238c.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              постановлением Администрации от 19.09.2023 № 1508
            </a>{" "}
            (далее – Порядок).
          </p>
          <p>
            Порядок размещен по
            <a
              href="https://old.admhimki.ru/maloe-i-srednee-predprinimatelstvo/formy-finansovoj-podderzhki-msp-v-moskovskoj-oblasti/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              ссылке.
            </a>
          </p>
          <div className="mt38 fw600">Принятие решения по итогам Конкурса.</div>
          <p>
            Итоги Конкурса определяются Конкурсной комиссией на основании
            результатов по рассмотрению, поданных участниками конкурса заявок.
          </p>
          <div className="mt38 fw600">
            Заключение договора с победителями Конкурса.
          </div>
          <p>
            Предоставление целевых бюджетных средств городского округа Химки
            Московской области.
          </p>
          <p>
            Договоры о предоставлении субсидии между Администрацией и
            победителями Конкурса должны быть заключены в течение 30 (тридцати)
            календарных дней с даты утверждения протокола Конкурсной комиссии о
            принятии решения о предоставлении субсидии.{" "}
          </p>
          <div className="mt38 fw600">
            Требования к участникам Конкурса на дату подачи заявки (далее –
            требования):
          </div>
          <ol>
            <li>
              участник Конкурса принадлежит к категории субъектов МСП в
              соответствии с Федеральным законом от 24.07.2007 № 209-ФЗ «О
              развитии малого и среднего предпринимательства» (далее Федеральный
              закон № 209-ФЗ) и состоит в реестре субъектов МСП;
            </li>
            <li>
              участник Конкурса зарегистрирован и осуществляет деятельность в
              качестве юридического лица или индивидуального предпринимателя на
              территории городского округа Химки Московской области;
            </li>
            <li>
              участник Конкурса:
              <ul>
                <li>
                  по мероприятию 02.01 осуществляет на территории Московской
                  области деятельность в сфере производства товаров (работ,
                  услуг) по видам деятельности в соответствии с разделами «A»,
                  «B», «C», «D», «E», «F», «H», «I», «J», «P», «Q», «R»,
                  классами 71, 75, 95, 96 ОКВЭД; раздел G. код 45.2.
                </li>
                <li>
                  по мероприятию 02.03 выполняет как минимум одно из следующих
                  условий: включение в перечень субъектов МСП, имеющих статус
                  социальных предприятий, формируемый Мининвестом Московской
                  области; основным видом деятельности участника Конкурса
                  является один из следующих видов деятельности: образование
                  дополнительное детей и взрослых; предоставление услуг по
                  дневному уходу за детьми; производство изделий
                  народно-художественных промыслов;
                </li>
              </ul>
            </li>
            <li>
              участник Конкурса не имеет неисполненной обязанности по уплате
              налогов, сборов, страховых взносов, пеней, штрафов, процентов,
              подлежащих уплате в соответствии с законодательством Российской
              Федерации о налогах и сборах;
            </li>
            <li>
              участник Конкурса не имеет просроченной (неурегулированной)
              задолженности по возврату в бюджет городского округа Химки
              Московской области субсидий, бюджетных инвестиций, предоставленных
              в том числе в соответствии с иными правовыми актами, а также иной
              просроченной (неурегулированной) задолженности по денежным
              обязательствам перед бюджетом городского округа Химки Московской
              области;
            </li>
            <li>
              участник Конкурса не находится в процессе реорганизации (за
              исключением реорганизации в форме присоединения к участнику
              Конкурса другого юридического лица), ликвидации, введения в его
              отношении процедуры банкротства, приостановления его деятельности
              в порядке, предусмотренном законодательством Российской Федерации
              (для юридических лиц);
            </li>
            <li>
              участник Конкурса не прекратил свою деятельность в качестве
              индивидуального предпринимателя и не находится в процессе введения
              в его отношении процедуры банкротства (для индивидуальных
              предпринимателей);
            </li>
            <li>
              участник Конкурса не должен являться иностранным юридическим
              лицом, офшорной компанией, а также российским юридическим лицом, в
              уставном (складочном) капитале которого доля прямого или
              косвенного (через третьих лиц) участия офшорных компаний в
              совокупности превышает 25 процентов (если иное не предусмотрено
              законодательством Российской Федерации). При расчете доли участия
              офшорных компаний в капитале российских юридических лиц не
              учитывается прямое и (или) косвенное участие офшорных компаний в
              капитале публичных акционерных обществ (в том числе со статусом
              международной компании), акции которых обращаются на
              организованных торгах в Российской Федерации, а также косвенное
              участие таких офшорных компаний в капитале других российских
              юридических лиц, реализованное через участие в капитале указанных
              публичных акционерных обществ;
            </li>
            <li>
              участник Конкурса не является получателем средств из бюджета
              Московской области в соответствии с иными нормативными правовыми
              актами Московской области, муниципальными правовыми актами на цели
              предоставления Субсидии, указанные в пункте 4 настоящего Порядка;
            </li>
            <li>
              участник Конкурса не осуществляет производство и (или) реализацию
              подакцизных товаров, а также добычу и (или) реализацию полезных
              ископаемых, за исключением общераспространенных полезных
              ископаемых, если иное не предусмотрено Правительством Российской
              Федерации;
            </li>
            <li>
              ранее в отношении участника Конкурса не было принято решение об
              оказании аналогичной поддержки из федерального или регионального
              бюджетов (поддержки, условия оказания которой совпадают, включая
              форму, вид поддержки и цели ее оказания) и сроки ее оказания не
              истекли;
            </li>
            <li>
              участник Конкурса не относится к субъектам МСП, указанным в пункте
              4 части 5 статьи 14 Федерального закона № 209-ФЗ;
            </li>
            <li>
              участник Конкурса не должен находиться в перечне организаций и
              физических лиц, в отношении которых имеются сведения об их
              причастности к экстремистской деятельности или терроризму, либо в
              перечне организаций и физических лиц, в отношении которых имеются
              сведения об их причастности к распространению оружия массового
              уничтожения;
            </li>
            <li>
              участник Конкурса не должен находиться в РНП в связи с отказом от
              исполнения заключенных государственных (муниципальных) контрактов
              о поставке товаров, выполнении работ, оказании услуг по причине
              введения политических или экономических санкций иностранными
              государствами, совершающими недружественные действия в отношении
              Российской Федерации, граждан Российской Федерации или российских
              юридических лиц, и (или) введения иностранными государствами,
              государственными объединениями и (или) союзами и (или)
              государственными (межгосударственными) учреждениями иностранных
              государств мер ограничительного характера;
            </li>
            <li>
              участником Конкурса произведены затраты на приобретение и
              изготовление Оборудования либо произведена оплата первого взноса
              (аванса) по договору лизинга Оборудования в размере 100 процентов
              на дату подачи Заявки;
            </li>
            <li>
              участник Конкурса произвел приемку Оборудования по договорам на
              приобретение (изготовление) Оборудование и по договорам лизинга;
            </li>
            <li>
              участник Конкурса поставил на баланс Оборудование по договорам на
              приобретение (изготовление) Оборудования (обязательно для
              юридических лица, для индивидуальных предпринимателей – при
              наличии);
            </li>
            <li>
              участник Конкурса представил полный пакет документов согласно
              таблицам 2 - 4 к настоящему Порядку.{" "}
            </li>
          </ol>

          <div className="mt38 fw600">
            Порядок подачи заявок участниками Конкурса и требований,
            предъявляемых к форме и содержанию заявок
          </div>

          <p>
            Участник Конкурса, претендующий на получение Субсидии, представляет
            заявку, включающую заявление на предоставление Субсидии по форме
            согласно Приложению 2 к настоящему Порядку (далее – заявление), и
            перечень документов согласно таблицам 2 - 4 к настоящему Порядку
            (далее – документы), в электронной форме посредством портала РПГУ
            (далее – заявка).
          </p>
          <p>Заявление на предоставление Субсидии включает, в том числе:</p>
          <ol>
            <li>
              согласие на публикацию (размещение) в
              информационно-телекоммуникационной сети Интернет информации об
              участнике Конкурса, о подаваемой участником Конкурса заявке, иной
              информации об участнике Конкурса, связанной с соответствующим
              Конкурсом, а также согласие на обработку персональных данных (для
              физического лица);
            </li>
            <li>
              согласие на осуществление проверок (обследований),в том числе
              выездных, документов и (или) сведений, представленных для
              получения Субсидии, и запрос информации, уточняющей представленные
              в заявлении сведения, в том числе у юридических и физических лиц,
              упомянутых в заявлении.
            </li>
          </ol>
          <p>
            Для представления заявки участник Конкурса авторизуется на портале
            РПГУ, затем заполняет заявление с использованием специальной
            интерактивной формы в электронном виде и подписывает ЭП. Электронные
            образы документов согласно таблицам 2 - 4 к настоящему Порядку
            подписываются ЭП.
          </p>
          <p>
            В рамках одного Конкурса участником Конкурса может быть подана
            только 1 заявка.
          </p>
          <div className="mt38 fw600">
            Порядок отзыва заявки на участие в Конкурсе
          </div>

          <p>
            Участник Конкурса вправе отозвать представленную заявку и повторно
            подать заявку не позднее установленного объявлением о проведении
            Конкурса срока окончания приема заявок.
          </p>
          <p>
            Участник Конкурса направляет на электронный адрес Администрации
            уведомление об отзыве заявки в форме скан-образа письма,
            составленного в свободной форме, подписанного руководителем
            юридического лица или индивидуальным предпринимателем и заверенного
            печатью (при наличии печати).
          </p>

          <div className="mt38 fw600">
            Правила рассмотрения и оценки заявок участников Конкурса
          </div>
          <p>
            МКУ «Малый бизнес Химки» обеспечивает прием и регистрацию заявок.
            При приеме заявки МКУ «Малый бизнес Химки осуществляет проверку
            заявки на предмет:
          </p>

          <ol>
            <li>
              подачи заявки на предоставление Субсидии, предусмотренной
              настоящим Порядком, в сроки, предусмотренные объявлением о
              проведении Конкурса;
            </li>
            <li>
              комплектности документов заявки согласно таблице 2 к настоящему
              Порядку;
            </li>
            <li>
              корректности заполнения обязательных полей в форме заявления на
              портале РПГУ;
            </li>
            <li>
              представления электронных образов документов посредством портала
              РПГУ, позволяющих в полном объеме прочитать текст документа и
              (или) распознать обязательные реквизиты документов;
            </li>
            <li>
              соответствия участника Конкурса категориям лиц, установленным
              пунктом 5, и требованиям, установленным в подпунктах 9-14 пункта
              11 настоящего Порядка.
            </li>
          </ol>
          <p>Основаниями для отказа в приеме и регистрации заявки являются:</p>
          <ol>
            <li>
              подача заявки на предоставление Субсидии, не предусмотренной
              настоящим Порядком;
            </li>
            <li>
              подача заявки на предоставление Субсидии в сроки, не
              предусмотренные объявлением о проведении Конкурса;
            </li>
            <li>
              несоответствие участника Конкурса категориям лиц, установленным
              пунктом 5, и требованиям, установленным в подпунктах 9 - 14 пункта
              11 настоящего Порядка;
            </li>
            <li>
              некомплектности документов заявки согласно таблице 2 к настоящему
              Порядку;
            </li>
            <li>
              наличие принятой и зарегистрированной заявки участника Конкурса,
              которая не была им отозвана.
            </li>
          </ol>
          <p>
            Отказ в приеме и регистрации заявки не препятствует повторному
            обращению участника Конкурса за предоставлением Субсидии до даты
            окончания приема заявок, установленной объявлением о проведении
            Конкурса.
          </p>
          <p>
            При наличии оснований для отказа в приеме и регистрации заявки,
            установленных пунктом 15 настоящего Порядка, Администрация
            отказывает в приеме и регистрации заявки не позднее 1 рабочего дня
            со дня подачи заявки и направляет участнику Конкурса решение об
            отказе в приеме и регистрации заявки по форме согласно Приложению 9
            в личный кабинет на портал РПГУ.
          </p>
          <p>
            При отсутствии оснований для отказа в приеме и регистрации заявки
            Администрация не позднее 1 рабочего дня со дня подачи Заявки:
          </p>
          <ol>
            <li>регистрирует заявку;</li>
            <li>
              запрашивает у ФНС России в порядке межведомственного электронного
              информационного взаимодействия (далее – межведомственный запрос):
              <ul>
                <li>сведения из ЕГРЮЛ (ЕГРИП);</li>
                <li>
                  сведения о наличии (отсутствии) неисполненной обязанности по
                  уплате налогов, сборов, страховых взносов, задолженности по
                  пеням, штрафов, процентов;
                </li>
                <li>сведения о среднесписочной численности работников. </li>
              </ul>
            </li>
          </ol>

          <p>
            После регистрации заявки МКУ «Малый бизнес Химки» рассматривает ее
            на предмет соответствия участника Конкурса требованиям,
            установленным подпунктами 1 - 8 пункта 11 настоящего Порядка,
            соответствия затрат, произведенных участником Конкурса, целям
            предоставления Субсидии и видам затрат, установленным пунктом 4
            настоящего Порядка, а также проверяет полноту, комплектность и
            соответствие установленным требованиям документов по затратам
            согласно таблицам 3 - 4 к настоящему Порядку (далее - документы по
            затратам).
          </p>

          <p>
            Заявки рассматриваются в срок не более 20 рабочих дней со дня
            окончания срока приема заявок.
          </p>
          <p>
            МКУ «Малый бизнес Химки» проводит проверку достоверности сведений,
            содержащихся в заявке участника Конкурса, следующими способами:
          </p>

          <ol>
            <li>
              сравнивает сведения, содержащиеся в заявке участника Конкурса с
              данными из открытых источников на сайте ФНС России;
            </li>
            <li>
              направляет в государственные и муниципальные органы запросы,
              касающиеся сведений и данных, указанных в заявке;
            </li>
            <li>
              осуществляет выездное обследование на место ведения хозяйственной
              деятельности участника Конкурса с целью подтверждения сведений и
              документов, содержащихся в составе заявки и получения оригинала
              банковской выписки по счету участника Конкурса, подтверждающей
              осуществление затрат. Выезд осуществляется не позднее даты
              окончания рассмотрения заявок Администрацией.
            </li>
          </ol>
          <p>
            Администрация несет ответственность за качество рассмотрения заявок.
          </p>
          <p>
            По результатам рассмотрения заявки МКУ «Малый бизнес Химки»
            составляет одно из следующих заключений по формам, установленным
            настоящем порядком (далее – заключения):
          </p>
          <ol>
            <li>
              о соответствии участника Конкурса и заявки требованиям и условиям,
              установленным настоящим Порядком согласно Приложению 3 к
              настоящему Порядку;
            </li>
            <li>
              о несоответствии участника Конкурса и заявки требованиям и
              условиям, установленным настоящим Порядком согласно Приложению 4 к
              настоящему Порядку.
            </li>
          </ol>
          <p>
            Основаниями для составления МКУ «Малый бизнес Химки» заключения о
            несоответствии участника Конкурса и заявки требованиям и условиям,
            установленным настоящим Порядком, являются:
          </p>
          <ol>
            <li>
              несоответствие участника Конкурса требованиям, установленным в
              подпунктах 1 - 8 пункта 11 настоящего Порядка;
            </li>
            <li>
              несоответствие представленных документов по форме или содержанию
              требованиям законодательства Российской Федерации, настоящего
              Порядка;
            </li>
            <li>
              несоответствие представленной участником Конкурса заявки
              требованиям, установленным в объявлении о проведении Конкурса;
            </li>
            <li>
              несоответствие затрат, произведенных участником Конкурса, целям
              предоставления Субсидии и видам затрат, установленным пунктом 4
              настоящего Порядка;
            </li>
            <li>
              недостоверность представленной участником Конкурса информации, в
              том числе информации о месте нахождения и адресе юридического
              лица, а также недостоверность информации, содержащейся в
              документах, представленных участником Конкурса.
            </li>
          </ol>
          <p>
            Не позднее 2 рабочих дней со дня окончания рассмотрения заявок МКУ
            «Малый бизнес Химки» формирует реестр заявок, в отношении которых
            составлены заключения (далее – реестр заявок) по форме согласно
            Приложению 5 к настоящему Порядку.
          </p>
          <p>
            МКУ «Малый бизнес Химки» в соответствии с пунктом 8 настоящего
            Порядка формирует рейтинг заявок, в отношении которых составлены
            Заключения (далее – рейтинг заявок) по форме согласно Приложению 11
            к настоящему Порядку.
          </p>
          <p>
            Реестр заявок, рейтинг заявок и заключения МКУ «Малый бизнес Химки»
            рассматриваются Конкурсной комиссией.
          </p>
          <p>
            Администрация назначает дату, время и место заседания Конкурсной
            комиссии и организует ее проведение в срок, не превышающий 30
            календарных дней со дня окончания рассмотрения всех заявок
            Администрацией.
          </p>
          <p>
            О Конкурсной комиссии и ее персональный состав утверждаются
            постановлением Администрации.
          </p>
          <p>
            По итогам рассмотрения реестра заявок, рейтинга заявок и заключений
            МКУ «Малый бизнес Химки» Конкурсная комиссия принимает следующие
            решения рекомендательного характера:
          </p>
          <ol>
            <li>об утверждении рейтинга заявок;</li>
            <li>
              об отказе в предоставлении Субсидии участникам Конкурса по
              основаниям, установленным подпунктами 1 - 7 пункта 34 настоящего
              Порядка;
            </li>
            <li>о признании участников Конкурса победителями Конкурса.</li>
          </ol>
          <p>
            Решения Конкурсной комиссии оформляются протоколом заседания
            Конкурсной комиссии.
          </p>
          <p>
            В случае если после проведения заседания Конкурсной комиссии в
            бюджете будут предусмотрены дополнительные ассигнования на
            реализацию мероприятий 02.01 и 02.03, то Субсидия предоставляется
            участнику Конкурса, у которого размер Субсидии снижен в связи с
            недостаточностью бюджетных ассигнований.
          </p>
          <p>
            Администрация с учетом решений Конкурсной комиссии в срок не более 4
            рабочих дней со дня заседания Конкурсной комиссии принимает решения:
          </p>
          <ol>
            <li>об отказе в предоставлении Субсидии участникам Конкурса;</li>
            <li>о признании участников Конкурса победителями Конкурса.</li>
          </ol>
          <p>
            Решения Администрации оформляются постановлением Администрации.{" "}
          </p>
          <div className="mt38 fw600">
            Срок, в течение которого участник Конкурса, признанный победителем в
            Конкурсе (далее - победитель Конкурса), должен подписать соглашение
            о предоставлении Субсидии (далее - Соглашение), в случае принятия
            решения о предоставлении Субсидии победителю Конкурса
          </div>
          <p>
            Соглашение заключается в срок, не превышающий 6 рабочих дней со дня
            принятия Администрацией решения о предоставлении Субсидии получателю
            Субсидии в соответствии с пунктом 25 настоящего Порядка (далее -
            Решение), в следующем порядке (либо в обратном порядке):
          </p>
          <ol>
            <li>
              в течение 3 рабочих дней со дня принятия решения Администрация
              направляет получателю Субсидии Соглашение, подписанное
              уполномоченным должностным лицом Администрации;
              <br />
              Перечень должностных лиц Администрации, уполномоченных на
              подписание результата рассмотрения заявки, утверждается
              нормативным правовым актом Администрации.
            </li>
            <li>
              в течение 3 рабочих дней со дня получения Соглашения получатель
              Субсидии направляет в адрес Администрации Соглашение, подписанное,
              со своей стороны.
            </li>
          </ol>
          <p>
            Получатель Субсидии вправе отказаться от получения Субсидии,
            направив в Администрацию соответствующее уведомление на электронный
            адрес Администрации в форме скан-письма с отказом от получения
            Субсидии, составленного в свободной форме, подписанного
            руководителем юридического лица или индивидуальным предпринимателем
            и заверенного печатью (при наличии печати).
          </p>
          <p>
            Решение об отказе в предоставлении Субсидии оформляется в виде
            электронного документа по форме согласно Приложению 10 к настоящему
            Порядку, подписанного ЭП уполномоченного должностного лица
            Администрации, направляется в личный кабинет получателя Субсидии на
            РПГУ не позднее пяти рабочих дней, следующих за днем регистрации
            уведомления об отказе от предоставления Субсидии.
          </p>
          <p>
            В случае не подписания получателем Субсидии Соглашения в указанные
            выше сроки Администрация принимает решение об отказе в
            предоставлении Субсидии по основанию, предусмотренному подпунктом 9
            пункта 34 настоящего Порядка. Указанное решение оформляется
            постановлением Администрации.
          </p>
          <p>
            В случае отказа участника Конкурса, признанного победителем в
            соответствии с пунктом 25 настоящего Порядка, от заключения
            Соглашения на предоставление Субсидии, остаток нераспределенных
            бюджетных ассигнований, предусмотренных на реализацию мероприятий и
            распределяемых в рамках конкурса, предоставляется в виде Субсидии
            участникам Конкурса, заявки которых были отклонены по причине
            недостаточности бюджетных ассигнований, в порядке очередности их
            заявок в рейтинге.{" "}
          </p>
          <div className="mt38 fw600">
            Даты размещения результатов Конкурса на официальном сайте
            Администрации
          </div>
          <p>
            Не позднее 7 календарных дней, следующих за днем принятия
            Администрацией решения, в соответствии с пунктом 25 настоящего
            Порядка, на официальном сайте Администрации публикуется информация о
            результатах Конкурса, включающая:
          </p>
          <ol>
            <li>дату, время и место проведения рассмотрения заявок;</li>
            <li>дату, время и место оценки заявок;</li>
            <li>
              информацию об участниках Конкурса, заявки которых были
              рассмотрены;
            </li>
            <li>
              информацию об участниках Конкурса, заявки которых были отклонены,
              с указанием причин их отклонения;
            </li>
            <li>
              последовательность оценки заявок участников Конкурса, присвоенные
              заявкам участников Конкурса значения по каждому из предусмотренных
              критериев оценки заявок участников Конкурса, принятое на основании
              результатов оценки указанных предложений решение о присвоении
              таким заявкам порядковых номеров;
            </li>
            <li>
              наименование победителя Конкурса и планируемый размер
              предоставляемой ему Субсидии.
            </li>
          </ol>
          <div className="mt38 fw600">Результат предоставления Субсидий</div>
          <p>
            Результатом предоставления Субсидий является сохранение
            среднесписочной численности работников за год, следующий за годом
            получения Субсидии.
          </p>
          <p>
            Результат предоставления Субсидии рассчитывается как сохранение
            среднесписочной численности работников за год, следующий за годом
            получения Субсидии, в размере не менее среднесписочной численности
            работников получателя Субсидии за год, предшествующий году получения
            Субсидии, или как разница среднесписочной численности работников по
            итогам года, следующего за годом получения Субсидии, к году,
            предшествующему году получения Субсидии.
          </p>
          <div className={`mt38 ${s.description}`}>
            Дата публикации: 13.11.2024
          </div>
        </div>
        <div className={`mt40 borderMain borderDocumentContainer`}>
          <DocumentContainerDownload
            type={"PDF"}
            text="Информация о результатах Конкурса по предоставлению финансовой поддержки субъектам малого и среднего предпринимательства на право получения субсидии за счет средств бюджета городского округа Химки Московской области на реализацию мероприятий подпрограммы"
            date="09.12.2024"
            document={
              "https://old.admhimki.ru/media/eds/elements/bb32c3b5-68a9-477f-918c-e414f6e526b2.pdf"
            }
          />
        </div>
      </ContantContainerMain>
    </div>
  );
};
export default ContestsFinancialSupport;
